<template>
  <div class="welcome">
    <div class="welcome__icon">
      <font-awesome-icon :icon="['fc', 'logo']" />
    </div>
    <div class="welcome__container">
      <div class="welcome__info">
        <div>
          <h1>Welcome to the <br class="line-break">Customer Dashboard</h1>
          <p class="">Log in to your FightCamp account with the same username and password you use on the FightCamp app.</p>
          <p>You must be the account owner in order to manage your account.</p>
        </div>
        <div class="welcome__support-info">
          <div>
            <p>For more assistance, contact our customer support team:</p>
            <a v-track-click="'Welcome_EmaiLink'" href="mailto:info@joinfightcamp.com">info@joinfightcamp.com</a><br>
          </div>
          <div class="welcome__support-hours">
            <p><strong>Support Hours:</strong></p>
            <p>11am - 8pm EST Mon-Fri</p>
            <p>11am - 4pm EST Sat-Sun</p>
            <br><br>
            <p>Chat and Phone</p>
            <p>+1 (240) 307-5890</p>
          </div>
        </div>
      </div>
      <div class="welcome__right-side">
        <div class="welcome__button-container">
          <button @click="handleAuth" class="button-normal button-layout button-cta" v-track-click="'Welcome_LoginBtn'">
            Log In To Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useRoute } from 'vue-router';
import { useAnalytics } from '../plugins/Segment';

export default {
  name: 'Welcome',
  setup() {
    const { loginWithRedirect } = useAuth0();
    const analytics = useAnalytics();

    onMounted(() => {
      analytics.pageViewed({ name: 'Welcome' });
    });

    const route = useRoute();
    function handleAuth() {
      const { email, key } = route.query;
      const encodedEmail = encodeURIComponent(email);
      loginWithRedirect({
        appState: {
          target: (email && key) ? `/link-account?email=${encodedEmail}&key=${key}` : '/dashboard',
        },
      });
    }

    return {
      handleAuth,
    };
  },
};

</script>

<style scoped lang="scss">
.welcome {
  height: 100%;
  color: $color-blue;

  &__icon {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 50px;
    left: 50px;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @media #{$tablet-up} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__info {
    order: 2;
    padding-left: 4rem;
    padding-right: 4rem;

    @media #{$tablet-up} {
      order: 1;
      width: 50%;
      padding-right: 7rem;
    }
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 0;
  }

  &__support-info {
    margin-top: 3rem;
  }

  &__support-hours {
    margin-top: 1rem;

    p {
      margin: 0;
    }
  }

  &__right-side {
    order: 1;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: $color-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding: 2rem;

    @media #{$tablet-up} {
      order: 2;
      width: 50%;
    }
  }

  &__button-container {
    img {
      width: 215px;
    }

    button {
      font-size: 20px;
      padding: 24px 40px;
    }
  }
}
</style>
